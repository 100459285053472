import React, {useState} from 'react';
import useDarkSide from '../hook/useDarkSide';
import {DarkModeSwitch} from 'react-toggle-dark-mode';

export default function Switcher() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(colorTheme === "light");

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };

    return (
        <DarkModeSwitch
            checked={darkSide}
            onChange={toggleDarkMode}
            size={21}
        />
    );
};